<script setup lang="ts">
import CardGrid from '@/components/layout/CardGrid.vue'
import LayoutSection from '@/components/layout/LayoutSection.vue'
import AppCard from '@/components/card/AppCard.vue'
import { MapPreset, Page } from '~/types/types'
import InformationCard from '@/components/card/InformationCard.vue'
import MapPresetsList from '~/components/MapPresetsList.vue'
const { find, findOne } = useStrapi()
useSeoMeta({
  title: () => `Drivmedla - Tillsammans banar vi väg för framtidens fordonsflotta`,
  description: () =>
    'Drivmedla.se är en öppen plattform som med hjälp av data, samarbete och strategisk vägledning accelererar omställningen till fossilfria transporter',
  ogTitle: () => `Drivmedla - Tillsammans banar vi väg för framtidens fordonsflotta`,
  ogDescription: () =>
    'Drivmedla.se är en öppen plattform som med hjälp av data, samarbete och strategisk vägledning accelererar omställningen till fossilfria transporter',
  ogLocale: () => 'sv_SE',
  ogSiteName: () => 'Drivmedla',
  ogUrl: () => `${useRuntimeConfig().public.baseURL}${useRoute().fullPath}`,
  ogImage: () => `${useRuntimeConfig().public.baseURL}/_nuxt/assets/_logo-horizontal-GREEN.svg`,
})

const { data: pageInfo } = await useAsyncData(
  'landing-page',
  () => findOne<Page>('landing-page', { populate: '*' }),
  { transform: (_pageInfo) => _pageInfo.data }
)

const { data: mapPresets } = await useAsyncData(
  'map-presets',
  () => find<MapPreset>('map-presets'),
  { transform: (_mapPresets) => _mapPresets.data }
)
// const linkToInfrastructureView = computed(() => {
//   if (!mapPresets.value) return null
//   const infrastructurePreset = mapPresets.value.find(
//     (preset) => preset.attributes.identifier === 'infrastructure-overall'
//   )
//   return infrastructurePreset ? `kartor-och-statistik/${infrastructurePreset.attributes.slug}` : null
// })
</script>
<template>
  <main>
    <AppHero
      :title="pageInfo?.attributes.generalContent.title"
      :description="pageInfo?.attributes.generalContent.ingress"
      type="frontpage"
      color="#fff"
    />
    <LayoutSection>
      <CardGrid :grid="2">
        <InformationCard
          title="För dig som är fordonsägare"
          description="För dig som fordonsägare erbjuder plattformen verktyg och resurser för investeringsplanering, samarbete med andra och främjande av hållbarhet inom transportsektorn."
          link="fordonsinnehavare"
          icon="BoltIcon"
        />
        <InformationCard
          title="För dig som är kommun eller region"
          description="För kommuner och regioner erbjuder plattformen stöd för att planera och utveckla en fossilfri fordonssektor genom geografisk planering, samarbete, statistik och kunskapsdelning för en effektiv omställning."
          link="kommun-region"
          icon="TruckIcon"
        />
        <InformationCard
          title="För ladd- och tankinfrastruktursaktörer"
          description="Drivmedla är en omfattande plattform som stöder aktörer inom laddnings- och bränsleinfrastruktur genom samarbete, statistik, kartor och kunskapsdelning, vilket möjliggör strategisk utveckling av infrastruktur och tjänster för en mer hållbar fordonssektor."
          link="ladd-tankinfrastruktur"
          icon="UserIcon"
        />
        <InformationCard
          title="För dig som äger mark eller elnät"
          description="Drivmedla stödjer elnätsbolag och markägare i att effektivt anpassa och utveckla infrastrukturer för ökad laddnings- och tankinfrastrukturefterfrågan. Plattformen främjar samarbete och beslutsfattande genom statistik och kunskap."
          link="mark-elnat"
          icon="UserIcon"
        />
      </CardGrid>
    </LayoutSection>
    <LayoutSection>
      <CardGrid :grid="4">
        <AppCard
          title="Samarbeta"
          description="Vi länkar samman aktörer som vill vara med och ställa om till fossilfria transporter"
          link="samarbeta"
        />
        <AppCard
          title="Utforska din region"
          description="Hur ser förutsättningarna och möjligheterna ut för fossilfria transporter i din region?"
          link="utforska-en-plats"
        />
        <AppCard
          title="Utforska statistik"
          description="Djupdyk i omställningsrelaterad statistik och data"
          link="kartor-och-statistik"
        />
        <AppCard
          title="Sök information"
          description="Hitta information och länkar kring omställningen till fossilfria drivmedel i kunskapsbanken"
          link="kunskapsbank"
        />
      </CardGrid>
    </LayoutSection>
    <LayoutSection>
      <AppHeading title="Utforska omställningen i Sverige" type="h2" size="large" />
      <MapPresetsList />
    </LayoutSection>
  </main>
</template>
